import React from "react";
import Page from "../../library/page/Page";
import "./MoreInfo.css";

const MoreInfo = () => {

    return (<Page>
        <div className="more-info">
            <div className="more-info__content">
                <div className="headline">Mehr Informationen</div>
                <div className="text">In diesem Abschnitt wird es in Zukunft Verweise auf die Technologien, Pläne etc.,
                    die diese Seite betreffen geben.
                </div>
                <div className="text">Leider ist dieser Abschnitt noch in Bearbeitung. Schau einfach später nochmal
                    vorbei.
                </div>
                <div className="text">Mehr informationen folgen...</div>
            </div>
        </div>

    </Page>);
};

export default MoreInfo;