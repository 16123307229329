import React from 'react';
import "./Pill.css";

const Pill = ({title,icon,customAction})=>{

    return(<div className="pill" onClick={customAction}>
        <div className="pill__icon">
            {icon}
        </div>
        <div className="pill__text">
            {title}
        </div>
    </div>);
};

export default Pill;