import React from "react";

const BurgerMenuSVG = () => {
    return (<svg viewBox="0 0 104 94"
                 version="1.1"
                 xmlns="http://www.w3.org/2000/svg"
                 style={{fillRule: "evenodd", clipRule: "evenodd", strokeLinejoin: "round", strokeMiterlimit: 2}}>
        <g transform="matrix(1,0,0,1,-444.938,-212.75)">
            <g transform="matrix(1,0,0,1,16,-46.875)">
                <path
                    d="M438.313,353.375C433.138,353.375 428.938,349.175 428.938,344C428.938,338.825 433.138,334.625 438.313,334.625L522.688,334.625C527.863,334.625 532.063,338.825 532.063,344C532.063,349.155 527.895,353.342 522.748,353.375L522.688,353.375L438.313,353.375ZM438.313,315.875C433.138,315.875 428.938,311.675 428.938,306.5C428.938,301.325 433.138,297.125 438.313,297.125L522.688,297.125C527.863,297.125 532.063,301.325 532.063,306.5C532.063,311.655 527.895,315.842 522.748,315.875L522.688,315.875L438.313,315.875ZM438.313,278.375C433.138,278.375 428.938,274.175 428.938,269C428.938,263.825 433.138,259.625 438.313,259.625L522.688,259.625C527.863,259.625 532.063,263.825 532.063,269C532.063,274.155 527.895,278.342 522.748,278.375L522.688,278.375L438.313,278.375Z"
                    style={{fill: "currentColor"}}/>
            </g>
        </g>
    </svg>);
}

export default BurgerMenuSVG;