import React, {useEffect} from 'react';
import "./Timeline.css";
import TimelineItem from "./timeline-item/TimelineItem";
import Connection from "./timeline-item-connection/Connection";
import scrollTo from "../../library/scroll/scrollTo";
import TimeIndicator from "./time-indicator/TimeIndicator";
import TimeConnection from "./time-connection/TimeConnection";
import i18n from "../../library/translation/Text";

const Timeline = () => {
    useEffect(() => {
        if (window.location.href.includes('target=timeline')) {
            scrollTo({id: '#timeline', offset: 64});
        }
    }, []);

    return (<div className="timeline">
        <div id="timeline" className="timeline__headline">Timeline</div>
        <div className="timeline__content">

            <TimeConnection date="Heute" align="center"/>

            <TimelineItem place={i18n('timeline_mps_place')}
                          title={i18n('timeline_mps_title')}
                          description={i18n('timeline_mps_text')}/>

            <TimeConnection date="01.08.2020" align="center"/>

            <TimelineItem place={i18n('timeline_self_employed_place')}
                          title={i18n('timeline_self_employed_title')}
                          description={i18n('timeline_self_employed_text')}/>

            <TimeConnection date="01.05.2020" align="center"/>

            <TimelineItem place={i18n('timeline_ionos_place')}
                          title={i18n('timeline_ionos_title')}
                          description={<>
                              <div className="text text--large">
                                  {i18n('timeline_ionos_text_part_0')}
                              </div>

                              <div className="text text--large">
                                  {i18n('timeline_ionos_text_part_1')}
                              </div>

                              <div className="text text--large">Projekte an denen ich bei IONOS maßgeblich beteiligt
                                  war:
                                  <ul>
                                      <li>Access / Hosting Contract Tool</li>
                                      <li>Hosting Control Panel (kompletter Account Bereich)</li>
                                      <li>Bestandskunden Shop</li>
                                      <li>EXOS (Frontend-) Framework für das IONOS UI</li>
                                      <li>EXOS Styleguide</li>
                                      <li>Contact Flow</li>
                                      <li>Hosting Partner Portal</li>
                                      <li>Middleware Controlpanel (basierend auf Spring & XSLT Generatoren)</li>
                                  </ul>
                              </div>
                          </>}/>

            <TimeConnection date="24.05.2018" align="center"/>

            <TimelineItem place={i18n('timeline_dual_apprenticeship_place')}
                          title={i18n('timeline_dual_apprenticeship_title')}
                          description={<>
                              <div>{i18n('timeline_dual_apprenticeship_text')}</div>
                              <div className="text text--large">Während meiner Ausbildung konnte ich verschiedene
                                  Bereiche innerhalb der United Internet Unternehmensgruppe durchlaufen:
                                  <ul>
                                      <li>Billing Operations (SQL Skripte zur Korrektur von Fehlern in den Billing
                                          Datenbanken)
                                      </li>
                                      <li>SSE (Service & Support Environment) Entwicklung unter Java 1.6 und JBoss 4
                                      </li>
                                      <li>1&1 Hosting Controlpanel - in Karlsruhe Java und XSLT Sheets</li>
                                      <li>1&1 Hosting Controlpanel - Testing von React Components mit Jest</li>
                                  </ul>
                                  und vieles mehr...
                              </div>
                          </>}/>

            <TimeConnection date="01.08.2015" align="center"/>

            <TimelineItem title={i18n('timeline_fachabitur_headline')}
                          place={i18n('timeline_fachabitur_place')}
                          description={<>
                              <div className="text text--large">{i18n('timeline_fachabitur_text')}</div>
                              <div className="text text--large">Die CBS versuchte alles um uns Schülern ein möglichst
                                  breites Wissen an die Hand zu geben.
                              </div>
                              <div className="text text--large">Unter anderem wurde uns</div>
                              <div className="text text--large">
                                  <ul>
                                      <li>Die Objekt-orientierte Programmierung in Java,</li>
                                      <li>SQL als Sprache und MS Access</li>
                                      <li>C auf Atmega32 Prozessoren</li>
                                  </ul>
                                  beigebracht.
                              </div>
                              <div className="text text--large">Zur selben Zeit experimentierte ich privat viel in 3D
                                  Engines. Dort nutzte ich meine neu erlangten Fähigkeiten um 3D Modelle zu generieren
                                  und Gameplay Skripte zu schreiben.
                              </div>
                          </>}/>

            <TimeConnection date="01.08.2012" align="center"/>

            <TimelineItem place={i18n('timeline_mittlere_reife_place')}
                          title={i18n('timeline_mittlere_reife_headline')}
                          description={<>
                              <div className="text text--large">{i18n('timeline_mittlere_reife_text')}</div>
                              <div className="text text--large">Auch wenn es nicht viel Input gab, reichte das dort
                                  vermittelte Grundwissen um meine Neugier zu wecken. Und so versuchte ich mich auch
                                  Zuhause hin und wieder an kleinen Projekten.
                              </div>
                          </>
                          }/>

            <TimeConnection date="01.08.2006" align="center"/>

            <TimelineItem title={i18n('timeline_elementary_school_headline')}
                          place={i18n('timeline_elementary_school_place')}
                          description={i18n('timeline_elementary_school_text')}/>
            <Connection/>
            <TimeIndicator date="01.08.2002"/>
        </div>

    </div>);
};

export default Timeline;