import React,{useEffect} from 'react';
import "./Profile.css";
import AvatarImg from "../../resources/images/Avatar.png";
import BracketsSVG from "../../resources/images/BracketsSVG";
import scrollTo from "../../library/scroll/scrollTo";
import i18n from "../../library/translation/Text";

const Profile = () => {

    useEffect(() => {
        if (window.location.href.includes('target=profile')) {
            scrollTo({id: '#profile', offset: 64});
        }
    }, []);

    return (<div id="profile" className="profile">
        <div className="profile__content">

            <div className="profile__content__avatar">
                <img className="profile__content__avatar__img" src={AvatarImg} alt="Jan-Gehrmann"/>
            </div>
            <div className="profile__content__info-wrapper">
                <div className="profile__content__info-wrapper__left-bracket">
                    <BracketsSVG/>
                </div>
                <div className="profile__content__info-wrapper__content">
                    <div className="profile__content__info-wrapper__content__text">{i18n('profile_name')}</div>
                    <div className="profile__content__info-wrapper__content__text profile__content__info-wrapper__content__text--small">{i18n('profile_job_title')}</div>
                    <div className="profile__content__info-wrapper__content__text profile__content__info-wrapper__content__text--small">{i18n('profile_birthdate')}</div>
                    <div className="profile__content__info-wrapper__content__text profile__content__info-wrapper__content__text--tiny">{i18n('profile_description')}</div>
                </div>
                <div className="profile__content__info-wrapper__right-bracket">
                    <BracketsSVG/>
                </div>
            </div>
        </div>
    </div>)
};

export default Profile;