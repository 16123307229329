import React from 'react';
import "./Contact.css";
import Pill from "../../library/pill/Pill";
import Discord from "../../resources/images/Discord";
import TwitterSVG from "../../resources/images/TwitterSVG";
import Message from "../../resources/images/Message";

const Contact = () => {
    const broadcastChannel = new BroadcastChannel('NOTIFICATION');

    const discordAction = () => {
        navigator.clipboard.writeText("jan-stefan#3732").then(() => {
            broadcastChannel.postMessage({text: "Discord-Name kopiert!"});
        }, () => {
            console.log("Error. Discord name could not be copied.");
        });
    };

    const twitterAction = () => {
        broadcastChannel.postMessage({text: "Weiterleiten in 3 Sekunden!"});
        setTimeout(() => {
            window.location.href = 'https://twitter.com/_stefangehrmann';
        }, 3000);
    };

    const emailAction = () => {
        navigator.clipboard.writeText("gehrmann.janstefan@gmail.com").then(() => {
            broadcastChannel.postMessage({text: "E-Mail Kopiert!"});
        }, () => {
            console.log("Error. Mail could not be copied.");
        });
    };

    return (<div className="contact">
        <div className="contact__content">
            <Pill icon={<Discord/>} title={"Discord"} customAction={discordAction}/>
            <Pill icon={<TwitterSVG/>} title={"Twitter"} customAction={twitterAction}/>
            <Pill icon={<Message/>} title={"Gehrmann.Janstefan@gmail.com"} customAction={emailAction}/>
        </div>
    </div>);
};

export default Contact;