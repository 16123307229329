import React, {useState, useEffect} from 'react';
import "./Navigation.css";
import BurgerMenuSVG from "../../resources/images/BurgerMenuSVG";
import NavigationConfig from "./NavigationConfig";
import CloseSVG from "../../resources/images/CloseSVG";

const Navigation = () => {
    const [isSmallVisible, setIsSmallVisible] = useState(false);

    const toggleSmallMenu = () => {
        if (isSmallVisible) {
            setIsSmallVisible(false);
            enablePage();
        } else {
            setIsSmallVisible(true);
            disablePage();
        }
    };

    const disablePage = () => {
        document.querySelector('#root').style.overflow = 'hidden';
    };

    const enablePage = () => {
        document.querySelector('#root').style.overflow = 'inherit';
    };

    useEffect(() => {
        window.onresize = () => {
            if (window.innerWidth >= 720) {
                setIsSmallVisible(false);
            }
        }
    }, []);

    const goToPage = (page) => {
        setIsSmallVisible(false);
        window.location.href = page;
    };

    return (<>
        <div className="navigation">
            <div className="navigation__mobile-toggle">
                <div className="navigation__mobile-toggle__wrapper" onClick={toggleSmallMenu}>
                    {isSmallVisible ? <CloseSVG/> : <BurgerMenuSVG/>}
                </div>
            </div>
            <div className="navigation__left">
                <div className="navigation__left__link" onClick={() => goToPage("/")}>jan.gehrmann.click</div>
            </div>
            <div className="navigation__center">
                {NavigationConfig.map((item, index) => (
                    <div key={index} className="navigation__center__link"
                         onClick={() => goToPage(item.link)}>{item.name}</div>))}
            </div>
        </div>
        {isSmallVisible ? <div className="small-navigation">
            {NavigationConfig.map((item, index) => (
                <div key={index} className="small-navigation__item"
                     onClick={() => goToPage(item.link)}>{item.name}</div>))}
        </div> : null}
    </>);
}

export default Navigation;