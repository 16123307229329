import React from 'react';
import Page from "../../library/page/Page";
import Profile from "../profile/Profile";
import Contact from "../contact/Contact";
import Skills from "../skills/Skills";
import Timeline from "../timeline/Timeline";
import OtherSkills from "../other-skills/OtherSkills";

const LandingPage = ()=>{

    return(<Page>
        <Profile/>
        <Contact/>
        <Skills/>
        <OtherSkills/>
        <Timeline/>
    </Page>);
};

export default LandingPage;