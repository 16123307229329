import React from "react";

const CheckMarkSVG = () => {

    return (<svg viewBox="0 0 33 25"
                 version="1.1"
                 xmlns="http://www.w3.org/2000/svg"
                 style={{
                     fillRule: "evenodd",
                     clipRule: "evenodd",
                     strokeLinejoin: "round",
                     strokeMiterlimit: 2
                 }}>
        <g transform="matrix(1,0,0,1,-694,-496)">
            <g id="Checked-Icon" transform="matrix(2.19671,0,0,2.19671,375.481,-2858.38)">
                <path
                    d="M158.225,1527.31L150.108,1535.5L146.764,1532.12C146.36,1531.71 145.705,1531.71 145.301,1532.12C144.897,1532.53 144.897,1533.19 145.301,1533.6L149.355,1537.7C149.563,1537.9 149.836,1538.01 150.108,1538C150.38,1538.01 150.654,1537.9 150.861,1537.7L159.688,1528.78C160.092,1528.38 160.092,1527.71 159.688,1527.31C159.284,1526.9 158.629,1526.9 158.225,1527.31Z"
                    style={{fill: "currentColor"}}/>
            </g>
        </g>
    </svg>);
};

export default CheckMarkSVG;