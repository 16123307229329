import React, {useEffect} from 'react';
import "./Skills.css";
import SkillIndicator from "../../library/skill-indicator/SkillIndicator";
import scrollTo from "../../library/scroll/scrollTo";
import i18n from "../../library/translation/Text";

const Skills = () => {

    useEffect(() => {
        if (window.location.href.includes('target=skills')) {
            scrollTo({id: '#skills', offset: 64});
        }
    }, []);

    return (<div className="skills">
        <div id="skills" className="skills__content__title">Skills</div>
        <div className="skills__content">

            {/*<div className="skills__content__info-box">*/}
            {/*    <div className="skills__content__info-box__text">*/}
            {/*        Hinweis: Klicke einen Skill an um mehr darüber zu erfahren.*/}
            {/*    </div>*/}
            {/*    <div className="skills__content__info-box__action">Okey</div>*/}
            {/*</div>*/}

            <SkillIndicator title={i18n('skills_javascript_vanilla_title')} amount={90}
                            description={i18n('skills_javascript_vanilla_description')}/>
            <SkillIndicator title={i18n('skills_javascript_react_title')} amount={100}
                            description={i18n('skills_javascript_react_description')}/>
            <SkillIndicator title={i18n('skills_java_title')} amount={70} description={i18n('skill_java_description')}/>
            <SkillIndicator title={i18n('skills_english_title')} amount={74} description={i18n('skills_english_description')}/>
        </div>
    </div>);
};

export default Skills;