const de_DE = {
    profile_name: "Jan Stefan Gehrmann",
    profile_job_title: "Software Entwickler",
    profile_description: "Seit meinem 15-ten Lebensjahr entwickle ich leidenschaftlich gerne Software. Was als Hobby anfing, machte ich später zum Beruf.",
    profile_birthdate: "Geb. am 01. 07. 1995",

    skills_javascript_vanilla_title: "Javascript (Vanilla JS)",
    skills_javascript_vanilla_description: "Seit mehreren Jahren verwende ich Javascript regelmäßig in ganz unterschiedlichen Projekten. JS ist mittlerweile zu einer Art Lieblingssprache geworden.",

    skills_javascript_react_title: "React & JSX",
    skills_javascript_react_description: "React ist für mich eine der größten positiven Überraschungen der letzten Jahre. Die Library ist so simple und gleichzeitig so mächtig, dass Webprojekte damit besonders viel Freude bereiten.",

    skills_java_title: "Java",
    skill_java_description: "Java war seit meinem Fachabitur meine Lehrsprache. Sie sollte sich allerdings nicht nur als guten Start in die Entwicklung entpuppen sondern zog sich bis heute immer durch meine junge Karriere. Ich nutze Java nach wie vor täglich in Job und privat, denke aber auch, dass die Sprache noch einiges für mich zu bieten hat.",

    skills_english_title: "Englisch",
    skills_english_description: "Dank der Arbeit bei IONOS, in überwiegend englisch-sprachigen Teams, konnte ich meine Englisch Kenntnisse sehr gut ausbauen.",


    timeline_mps_title: "Senior Java Developer",
    timeline_mps_place :"MPS GmbH",
    timeline_mps_text: "Bei der MPS GmbH in Koblenz bekam ich Mitte 2020 die Möglichkeit als Senior Java Developer an den neuen Prototypen für das K1 Projekt mitzuwirken.",


    timeline_self_employed_title: "Eigene Hosting Projekte",
    timeline_self_employed_place: "Selbständig",
    timeline_self_employed_text: "Nachdem ich soviel über die Hosting-Welt und Portal Software gelernt hatte, wollte ich mich an eigenen Hosting Produkten versuchen. Ich gründete Virtuelle Wolke und bot eigene V-Server und Domain Produkte an.",


    timeline_ionos_title: "Junior Developer",
    timeline_ionos_place: "IONOS",
    timeline_ionos_text_part_0: `Da mir die Arbeit an Frontends am meisten Freude bereitete, zog es mich in den Süden zu 1&1 Hosting. Dort trat ich meine erste Festanstellung als Junior Developer an.`,
    timeline_ionos_text_part_1: `Aus der Hosting Marke wurde zu diesem Zeitpunkt IONOS. Dadurch stimmte das Timing um am gesamten Rebranding der Hosting Marke mitwirken zu können. Unter anderem übernahm ich dort die Verantwortung für das Contract Tool, dem Frontend für Vetragskündigungen / Verlängerungen etc. Später übernahm unser Team die Online Shops.`,


    timeline_dual_apprenticeship_title: "Ausbildung",
    timeline_dual_apprenticeship_place: "1&1 Internet AG",
    timeline_dual_apprenticeship_text: "Bei der 1&1 Internet AG schloss ich meine Ausbildung als Fachinformatiker in Fachrichtung Anwendungsentwicklung ab.",


    timeline_fachabitur_headline: "Fachhochschulreife",
    timeline_fachabitur_place: "CBS Technik Koblenz",
    timeline_fachabitur_text: "Nach meiner Mittleren Reife wechselte ich zur CBS (Carl Benz Schule) Technik in Koblenz um dort mein Fachabitur zu erlangen.",


    timeline_mittlere_reife_headline: "Mittlere Reife",
    timeline_mittlere_reife_place: "IGS Selters",
    timeline_mittlere_reife_text: "In der Mittelstufe gab es erste kleine Ausflüge in die Webentwicklung. Informatik Wahlpflichtfächer sollten uns erste kleine Erfahrungen mit HTML und CSS ermöglichen.",


    timeline_elementary_school_headline: "Schuleintritt",
    timeline_elementary_school_place: "Grundschule Marienrachdorf",
    timeline_elementary_school_text: "An der kleinen Grundschule in Marienrachdorf fand meine Schulbildung seinen Anfang."

};

export default de_DE;