import React from "react";
import Page from "../../library/page/Page";
import "../../library/text/Text.css";
import "./Impressum.css";

const Impressum = () => {

    return (<Page>
        <div className="impressum">
            <div className="impressum__content">
                <div className="headline">Impressum</div>
                <div className="text">Informationspflicht laut § 5 TMG.</div>

                <div className="text">
                    Stauffenbergallee 20, <br/>
                    56410 Montabaur,<br/>
                    Deutschland <br/>
                </div>

                <div className="text">
                    {/*<span className="text-inline-bold">Tel.:</span> 015758198127 <br/>*/}
                    <span className="text-inline-bold">E-Mail:</span> gehrmann.janstefan@gmail.com <br/>
                </div>

                <div className="text">
                    <span className="text-inline-bold">Berufsbezeichnung:</span> Fachinformatiker in Fachrichtung Anwendungsentwicklung
                </div>

                <div className="text">
                    <span className="text-inline-bold">Kontaktdaten des Verantwortlichen für Datenschutz</span>
                    <p/>
                    Sollten Sie Fragen zum Datenschutz haben, finden Sie nachfolgend die Kontaktdaten der verantwortlichen Person bzw. Stelle: <br/>
                    <br/>
                    Jan Stefan Gehrmann <br/>
                    Stauffenbergallee 20, 56410 Montabaur, Deutschland <br/>
                    E-Mail-Adresse: gehrmann.janstefan@gmail.com
                </div>

                <div className="headline headline--sub">EU-Streitschlichtung</div>
                <div className="text">
                    Gemäß Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten (ODR-Verordnung) möchten wir Sie über die Online-Streitbeilegungsplattform (OS-Plattform) informieren. <br/>
                    Verbraucher haben die Möglichkeit, Beschwerden an die Online Streitbeilegungsplattform der Europäischen Kommission unter <p/>
                    <a href="http://ec.europa.eu/odr?tid=321284692" target="_blank" rel="noreferrer">http://ec.europa.eu/odr?tid=321284692</a> <p/>
                    zu richten. <br/>
                    Die dafür notwendigen Kontaktdaten finden Sie oberhalb in unserem Impressum. <p/>
                    Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit oder verpflichtet sind, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
                </div>

                <div className="headline headline--sub">Haftung für Inhalte dieser Website</div>
                <div className="text">Wir entwickeln die Inhalte dieser Webseite ständig weiter und bemühen uns korrekte und aktuelle Informationen bereitzustellen. Laut Telemediengesetz (TMG) §7 (1) sind wir als Diensteanbieter für eigene Informationen, die wir zur Nutzung bereitstellen, nach den allgemeinen Gesetzen verantwortlich. Leider können wir keine Haftung für die Korrektheit aller Inhalte auf dieser Webseite übernehmen, speziell für jene die seitens Dritter bereitgestellt wurden. Als Diensteanbieter im Sinne der §§ 8 bis 10 sind wir nicht verpflichtet, die von ihnen übermittelten oder gespeicherten Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.</div>
                <div className="text">Unsere Verpflichtungen zur Entfernung von Informationen oder zur Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen aufgrund von gerichtlichen oder behördlichen Anordnungen bleiben auch im Falle unserer Nichtverantwortlichkeit nach den §§ 8 bis 10 unberührt.</div>
                <div className="text">Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen, bitte wir Sie uns umgehend zu kontaktieren, damit wir die rechtswidrigen Inhalte entfernen können. Sie finden die Kontaktdaten im Impressum.</div>

                <div className="headline headline--sub">Haftung für Links auf dieser Website</div>
                <div className="text">Unsere Webseite enthält Links zu anderen Webseiten für deren Inhalt wir nicht verantwortlich sind. Haftung für verlinkte Websites besteht für uns nicht, da wir keine Kenntnis rechtswidriger Tätigkeiten hatten und haben, uns solche Rechtswidrigkeiten auch bisher nicht aufgefallen sind und wir Links sofort entfernen würden, wenn uns Rechtswidrigkeiten bekannt werden.</div>
                <div className="text">Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitte wir Sie uns zu kontaktieren. Sie finden die Kontaktdaten im Impressum.</div>

                <div className="headline headline--sub">Urheberrechtshinweis</div>
                <div className="text">Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos) unterliegen dem Urheberrecht der Bundesrepublik Deutschland. Bitte fragen Sie uns bevor Sie die Inhalte dieser Website verbreiten, vervielfältigen oder verwerten wie zum Beispiel auf anderen Websites erneut veröffentlichen. Falls notwendig, werden wir die unerlaubte Nutzung von Teilen der Inhalte unserer Seite rechtlich verfolgen.</div>
                <div className="text">Sollten Sie auf dieser Webseite Inhalte finden, die das Urheberrecht verletzen, bitten wir Sie uns zu kontaktieren.</div>

                <div className="headline headline--sub">Bildernachweis</div>
                <div className="text">Die Bilder, Fotos und Grafiken auf dieser Webseite sind urheberrechtlich geschützt.</div>
                <div className="text">Die Bilderrechte liegen bei den folgenden Fotografen und Unternehmen:</div>
                <div className="text">
                    <ul>
                        <li>Jan Stefan Gehrmann</li>
                        <li>Julian Westermann</li>
                    </ul>
                </div>
            </div>

        </div>
    </Page>)
};

export default Impressum;