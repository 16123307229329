import React, {useState, useEffect} from "react";
import "./Notification.css";
import CheckMarkSVG from "../../resources/images/CheckMarkSVG";

const Notification = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [text, setText] = useState(null);
    const broadcastChannel = new BroadcastChannel('NOTIFICATION');

    let timeoutHandle;

    useEffect(() => {
        broadcastChannel.addEventListener('message', (event) => {
            setText(event.data.text);

            setIsVisible(true);
            window.clearTimeout(timeoutHandle);

            timeoutHandle = setTimeout(() => {
                setIsVisible(false);
            }, 2000);
        });

    }, []);

    return (<div className={isVisible ? "notification notification--visible" : "notification"}>
        <CheckMarkSVG/>
        <div className="notification__text">{text}</div>
    </div>);
};

export default Notification;