import React from 'react';
import "./Connection.css";

const Connection = ({hiddenCircle}) => {
    return (<div className="timeline-item-connection">

        {hiddenCircle ? null : <div className="timeline-item-connection__circle">
            <div className="timeline-item-connection__circle__inner-circle"/>
        </div>}
        <div className="timeline-item-connection__bar"/>
    </div>);
}

export default Connection;