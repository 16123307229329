import React from 'react';

const TwitterSVG = () => {

    return (<svg viewBox="0 0 25 20"
                 version="1.1"
                 xmlns="http://www.w3.org/2000/svg"
                 style={{fillRule: "evenodd", clipRule: "evenodd", strokeLinejoin: "round", strokeMiterlimit: 2}}>
        <g transform="matrix(1,0,0,1,-175.5,-2697)">
            <g transform="matrix(1,0,0,-1,175.5,2765.08)">
                <path
                    d="M22.094,63.098C22.109,62.879 22.109,62.66 22.109,62.441C22.109,55.77 17.031,48.082 7.75,48.082C4.891,48.082 2.234,48.91 0,50.348C0.406,50.301 0.797,50.285 1.219,50.285C3.578,50.285 5.75,51.082 7.484,52.441C5.266,52.488 3.406,53.941 2.766,55.941C3.078,55.895 3.391,55.863 3.719,55.863C4.172,55.863 4.625,55.926 5.047,56.035C2.734,56.504 1,58.535 1,60.988L1,61.051C1.672,60.676 2.453,60.441 3.281,60.41C1.922,61.316 1.031,62.863 1.031,64.613C1.031,65.551 1.281,66.41 1.719,67.16C4.203,64.098 7.937,62.098 12.125,61.879C12.047,62.254 12,62.645 12,63.035C12,65.816 14.25,68.082 17.047,68.082C18.5,68.082 19.812,67.473 20.734,66.488C21.875,66.707 22.969,67.129 23.937,67.707C23.562,66.535 22.766,65.551 21.719,64.926C22.734,65.035 23.719,65.316 24.625,65.707C23.938,64.707 23.078,63.816 22.094,63.098Z"
                    style={{fill: "currentColor", fillRule: "nonzero"}}/>
            </g>
        </g>
    </svg>);
}

export default TwitterSVG;