import React from "react";
import "./TimeIndicator.css";

const TimeIndicator = ({date}) => {
    return (<div className="time-indicator">

        <div className="time-indicator__content">
            {date}
        </div>
    </div>);
};

export default TimeIndicator;