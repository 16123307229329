import React from 'react';

const Discord = () => {

    return (<svg width="100%" height="100%" viewBox="0 0 58 42" version="1.1" xmlns="http://www.w3.org/2000/svg"
                 style={{fillRule: "evenodd", clipRule: "evenodd", strokeLinejoin: "round", strokeMiterlimit: 2}}>
        <g transform="matrix(1,0,0,1,-798.82,-1135.81)">
            <g transform="matrix(6.08251,0,0,6.08251,-1702.8,60.6974)">
                <g transform="matrix(0.0806448,0,0,0.0806448,406.063,171.384)">
                    <path
                        d="M150.9,150.6C150.9,150.6 147.3,146.3 144.3,142.5C157.4,138.8 162.4,130.6 162.4,130.6C158.3,133.3 154.4,135.2 150.9,136.5C145.9,138.6 141.1,140 136.4,140.8C126.8,142.6 118,142.1 110.5,140.7C104.8,139.6 99.9,138 95.8,136.4C93.5,135.5 91,134.4 88.5,133C88.2,132.8 87.9,132.7 87.6,132.5C87.4,132.4 87.3,132.3 87.2,132.2C85.4,131.2 84.4,130.5 84.4,130.5C84.4,130.5 89.2,138.5 101.9,142.3C98.9,146.1 95.2,150.6 95.2,150.6C73.1,149.9 64.7,135.4 64.7,135.4C64.7,103.2 79.1,77.1 79.1,77.1C93.5,66.3 107.2,66.6 107.2,66.6L108.2,67.8C90.2,73 81.9,80.9 81.9,80.9C81.9,80.9 84.1,79.7 87.8,78C98.5,73.3 107,72 110.5,71.7C111.1,71.6 111.6,71.5 112.2,71.5C118.3,70.7 125.2,70.5 132.4,71.3C141.9,72.4 152.1,75.2 162.5,80.9C162.5,80.9 154.6,73.4 137.6,68.2L139,66.6C139,66.6 152.7,66.3 167.1,77.1C167.1,77.1 181.5,103.2 181.5,135.4C181.5,135.4 173,149.9 150.9,150.6ZM104.4,103.9C98.7,103.9 94.2,108.9 94.2,115C94.2,121.1 98.8,126.1 104.4,126.1C110.1,126.1 114.6,121.1 114.6,115C114.7,108.9 110.1,103.9 104.4,103.9ZM140.9,103.9C135.2,103.9 130.7,108.9 130.7,115C130.7,121.1 135.3,126.1 140.9,126.1C146.6,126.1 151.1,121.1 151.1,115C151.1,108.9 146.6,103.9 140.9,103.9Z"
                        style={{fill:"currentColor"}}/>
                </g>
            </g>
        </g>
    </svg>);
};

export default Discord;