const NavigationConfig = [
    {
        name: "Profile",
        link: "/?target=profile"
    },
    {
        name: "Timeline",
        link: "/?target=timeline"
    },
    {
        name: "Skills",
        link: "/?target=skills"
    }
];

export default NavigationConfig;