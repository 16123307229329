import React from "react";
import "./OtherSkills.css";
import SkillPill from "./SkillPill";

const OtherSkills = () => {

    return (<div className="other-skills">
        <div className="other-skills__heading-container">
            <div className="other-skills__heading-container__headline">Weitere Skills</div>
        </div>
        <div className="other-skills__content">
            <SkillPill title="HTML 5"/>
            <SkillPill title="CSS / SASS"/>
            <SkillPill title="BEM"/>
            <SkillPill title="MEL (Maya Embedded Language)"/>
            <SkillPill title="Node.js"/>
            <SkillPill title="Jest"/>
            <SkillPill title="QUnit"/>
            <SkillPill title="Vue.js"/>
            <SkillPill title="C/C++"/>
            <SkillPill title="SMACCS"/>
            <SkillPill title="Linux (Debian Derivate)"/>
            <SkillPill title="Gefühl für gutes Design"/>
            <SkillPill title="Maven"/>
            <SkillPill title="Affinity Photo"/>
            <SkillPill title="Caddy"/>
            <SkillPill title="Affinity Designer"/>
            <SkillPill title="Adobe Photoshop"/>
            <SkillPill title="PHP"/>
            <SkillPill title="Autodesk Maya"/>
            <SkillPill title="Erfahrung in UX"/>
            <SkillPill title="Markdown"/>
            <SkillPill title="angular .js"/>
            <SkillPill title="Git"/>
            <SkillPill title="NPM"/>
            <SkillPill title="Webpack"/>
            <SkillPill title="Aurelia.js"/>
            <SkillPill title="Z-Brush (Pixologic)"/>
            <SkillPill title="Open GL / GLSL"/>
            <SkillPill title="SVN"/>
            <SkillPill title="Spring / Springboot"/>
            <SkillPill title="Spring MVC"/>
            <SkillPill title="JUnit 5"/>
            <SkillPill title="Apache 2 (Webserver)"/>
            <SkillPill title="Postgres DB"/>
            <SkillPill title="Maria DB / MySQL"/>
            <SkillPill title="Redis"/>
            <SkillPill title="JBoss 4"/>
            <SkillPill title="Wildfly 10"/>
            <SkillPill title="SH Scripts"/>
        </div>
    </div>);
};

export default OtherSkills;