import './App.css';
import React from "react";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import LandingPage from "./units/landingpage/LandingPage";
import Impressum from "./units/impressum/Impressum";
import Datenschutz from "./units/datenschutz/Datenschutz";
import MoreInfo from "./units/more-info/MoreInfo";

const App = () => {

    return (
        <Router>
            <Switch>
                <Route path={"/impressum"} component={Impressum}/>
                <Route path={"/datenschutz"} component={Datenschutz}/>
                <Route path={"/mehr-info"} component={MoreInfo}/>
                <Route path={"/"} component={LandingPage}/>
            </Switch>
        </Router>
    );
}

export default App;
