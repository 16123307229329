import React from 'react';
import "./Footer.css";

const Footer = () => {

    const goToPage = (page) => {
        window.location.href = page;
    };

    return (<div className="footer">
        <div className="footer__content">
            <div className="footer__content__link" onClick={() => goToPage("/datenschutz")}>Datenschutz</div>
            <div className="footer__content__link" onClick={() => goToPage("/impressum")}>Impressum</div>
            <div className="footer__content__link" onClick={() => goToPage("/mehr-info")}>Mehr Info.</div>
        </div>
    </div>);
};

export default Footer;