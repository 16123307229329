import React from 'react';

const Message = () => {

    return (<svg width="100%" height="100%"
                 viewBox="0 0 65 43"
                 version="1.1" xmlns="http://www.w3.org/2000/svg"
                 style={{
                     fill: "currentColor",
                     fillRule: "evenodd",
                     clipRule: "evenodd",
                     strokeLinejoin: "round",
                     strokeMiterlimit: 2
                 }}>
            <g transform="matrix(1,0,0,1,-305,-1134.19)">
                <g transform="matrix(6.08251,0,0,6.08251,-1702.8,60.6974)">
                    <g transform="matrix(0.164406,0,0,0.164406,280.115,-10.2084)">
                        <path
                            d="M367.933,1140.49C367.751,1140.41 367.538,1140.46 367.398,1140.6L350.805,1157.19C350.543,1157.45 350.543,1157.88 350.805,1158.14L364.302,1171.63C365.099,1172.4 365.123,1173.67 364.351,1174.47C363.584,1175.27 362.313,1175.29 361.516,1174.52L347.97,1160.98C347.709,1160.72 347.283,1160.72 347.022,1160.98L341.322,1166.68C338.451,1169.55 333.792,1169.55 330.915,1166.68L325.216,1160.98C324.954,1160.72 324.528,1160.72 324.267,1160.98L310.77,1174.48C309.973,1175.25 308.702,1175.22 307.929,1174.43C307.181,1173.65 307.181,1172.42 307.935,1171.64L321.432,1158.14C321.688,1157.88 321.688,1157.46 321.432,1157.19L304.839,1140.6C304.699,1140.46 304.487,1140.42 304.304,1140.5C304.122,1140.57 304.006,1140.75 304,1140.94L304,1173.06C304,1176.01 306.397,1178.41 309.353,1178.41L362.879,1178.41C365.835,1178.41 368.231,1176.01 368.231,1173.06L368.231,1140.94C368.231,1140.75 368.116,1140.57 367.933,1140.49ZM333.75,1163.84C335.057,1165.14 337.174,1165.14 338.482,1163.84L364.405,1137.92C364.929,1137.4 364.929,1136.55 364.405,1136.03C364.205,1135.82 363.937,1135.69 363.651,1135.65C363.396,1135.61 363.14,1135.59 362.885,1135.59L309.359,1135.59C309.097,1135.59 308.842,1135.61 308.586,1135.65C307.85,1135.75 307.345,1136.43 307.455,1137.16C307.491,1137.45 307.625,1137.72 307.832,1137.92L333.75,1163.84Z"
                            style={{fill: "currentColor", fillRule: "nonzero"}}/>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default Message;