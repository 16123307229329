const scrollTo = ({id, offset}) => {
    if (!id) {
        throw new Error('No Id provided to function scrollTo.');
    }

    const targetId = id;
    const userDefinedOffset = offset ? offset : 0;
    const targetIdSelector = document.querySelector(targetId).getBoundingClientRect();
    const windowOffset = window.pageYOffset;
    const targetOffset = targetIdSelector.top;

    const scrollPosition = ((windowOffset + targetOffset) - userDefinedOffset);

    window.scrollTo({
        top: scrollPosition,
        left: 0,
        behavior: "auto"
    });

};

export default scrollTo;