import React from 'react';
import Footer from "../../units/footer/Footer";
import Content from "./Content";
import Navigation from "../../units/navigation/Navigation";
import GdprBanner from "../../units/gdpr-banner/GdprBanner";
import Notification from "../notification/Notification";

const Page = (props) => {

    return (<>
        {(() => {
            if (!props.gdprDisabled) {
                return (<GdprBanner/>);
            }
        })()}
        <Navigation/>
        <Content>
            {props.children}
        </Content>
        <Footer/>
        <Notification/>
    </>);
};

export default Page;