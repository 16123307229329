import React from 'react';
import "./TimelineItem.css";

const TimelineItem = ({title,place, description}) => {

    return (<div className="timeline-item">
        <div className="timeline-item__time">{`${title} :: ${place}`}</div>
        <div className="timeline-item__description">{description}</div>
    </div>);
};

export default TimelineItem;