import React, {useState} from 'react';
import "./SkillIndicator.css";

const SkillIndicator = ({title, amount, description}) => {

    const [isSkillInformationVisible, setIsSkillInformationVisible] = useState(false);

    const getClassByAmount = () => {
        if (amount < 25) {
            return "skill-indicator__bar__amount skill-indicator__bar__amount--red";
        }

        if (amount < 50) {
            return "skill-indicator__bar__amount skill-indicator__bar__amount--orange";
        }

        if (amount < 75) {
            return "skill-indicator__bar__amount";
        }

        if (amount <= 100) {
            return "skill-indicator__bar__amount skill-indicator__bar__amount--green";
        }

        return "indicator__bar__amount";

    };

    const getDescriptionByAmount = () => {
        if (amount < 25) {
            return "Einstieg";
        }

        if (amount < 50) {
            return "Fortgeschritten";
        }

        if (amount < 75) {
            return "Breites Wissen / Solide Anwendung";
        }

        if (amount <= 100) {
            return "Sehr gute Kenntnisse";
        }

        return "Sehr gut";
    };

    const toggleSkillInformation = () => {
        if (isSkillInformationVisible) {
            setIsSkillInformationVisible(false);
        } else {
            setIsSkillInformationVisible(true);
        }
    };

    return (<div className={isSkillInformationVisible? "skill-indicator skill-indicator--open" :"skill-indicator"} onClick={toggleSkillInformation}>

        <div className="skill-indicator__title">
            <div className="skill-indicator__title__title-text">{title}</div>
        </div>

        <div className="skill-indicator__bar">
            <div className={getClassByAmount()} style={{width: amount + '%'}}/>
            <div className="skill-indicator__bar__text">{getDescriptionByAmount()}</div>
        </div>

        {isSkillInformationVisible ?
            <div className="skill-indicator__more-info-text">{description}</div> : null}
    </div>);
};

export default SkillIndicator;