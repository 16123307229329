import React, {useEffect, useState} from "react";
import "./GdprBanner.css";
import Cookies from "js-cookie";
import {NavLink} from "react-router-dom";

const GdprBanner = () => {

    const [isVisible, setIsVisible] = useState(false);
    const COOKIE = "SHOW_GDPR";

    const disablePage = () => {
        document.querySelector('#root').style.overflow = 'hidden';
    };

    const enablePage = () => {
        document.querySelector('#root').style.overflow = 'inherit';
    };

    const handleClick = () => {
        Cookies.set(COOKIE, "TRUE");
        setIsVisible(false);
        enablePage();
    };

    useEffect(() => {
        const storedIsVisible = Cookies.get(COOKIE);

        if (storedIsVisible === undefined) {
            setIsVisible(true);
            disablePage();
        } else {
            setIsVisible(false);
            enablePage();
        }

    }, []);

    if (isVisible) {
        return (<div className="gdpr-banner">
            <div className="gdpr-banner__banner-box">
                <div className="gdpr-banner__header">Cookies & Datenschutz</div>
                <div className="gdpr-banner__explaination">
                    Diese Seite verwendet Cookies um Dir einen möglichst angenehmen Besuch zu bereiten. <br/>
                    Mehr dazu findest Du unter dem Bereich <NavLink to="/datenschutz">Datenschutz</NavLink>.
                </div>
                <div className="gdpr-banner__actions">
                    <div className="gdpr-banner__actions__button" onClick={handleClick}>Okey :)</div>
                </div>
            </div>
        </div>);
    } else {
        return null;
    }
};

export default GdprBanner;