import React from 'react';

const BracketsSVG = () => {
    return (<svg viewBox="0 0 96 450"
                 version="1.1"
                 xmlns="http://www.w3.org/2000/svg"
                 style={{fill: "currentColor",fillRule:"evenodd",clipRule:"evenodd",strokeLinejoin:"round",strokeMiterlimit:2}}>
    <g transform="matrix(1,0,0,1,-432,-238)">
        <path d="M528,238C528,238 486.574,250.23 487,279C487.426,307.77 487,395.163 487,429C487,462.837 432,463 432,463C432,463 466.999,459.521 467,429C467.001,389.887 467.446,312.731 468,279C468.662,238.731 528,238 528,238"/>
        <g transform="matrix(-1,0,0,1,960,225)">
            <path d="M528,238C528,238 486.574,250.23 487,279C487.426,307.77 487,395.163 487,429C487,462.837 432,463 432,463C432,463 466.999,459.521 467,429C467.001,389.887 467.446,312.731 468,279C468.662,238.731 528,238 528,238"/>
        </g>
    </g>
</svg>)
};

export default BracketsSVG;