import React from "react";
import "./TimeConnection.css";

const TimeConnection = ({align, date}) => {

    const getAlignmentClass = () => {
        if (align && align === 'left') {
            return "time-connection time-connection--align-left";
        }

        if (align && align === 'right') {
            return "time-connection time-connection--align-right";
        }

        if (align && align === 'center') {
            return "time-connection time-connection--align-center";
        }
    };

    return (<div className={getAlignmentClass()}>
        <div className="time-connection__container">

            <div className="time-connection__container__bar">
                <div className="time-connection__container__circle-top">
                    <div className="time-connection__container__circle-top__inner-circle"/>
                </div>

            </div>

            <div className="time-connection__container__text">{date}</div>

            <div className="time-connection__container__bar">
                <div className="time-connection__container__circle-bottom">
                    <div className="time-connection__container__circle-bottom__inner-circle"/>
                </div>
            </div>
        </div>
    </div>);
}

export default TimeConnection;